var URL_SCHEME = 'myteam-messenger://';
const stampKey = "d"
const allowedArgs = {
    "d": true,
    "start": true,
}

export function goToDeeplink(url) {
    var deeplink = getDeeplink(url);
    var browser = detectBrowser();
    var func = null;
    if (browser.chrome) func = checkChrome;
    else if (browser.safari && browser.ios) func = checkIos;
    else if (browser.safari || browser.firefox) func = checkIframe;
    if (deeplink && func) {
        func(URL_SCHEME + deeplink);
    }
}

function getDeeplink(url) {
    var stamp = "";
    var otherParams = [];
    url.substr(1).split("&").forEach(function (part) {
        let [key, value] = part.split("=");
        if (allowedArgs[key]) {
            if (key === stampKey) {
                stamp = decodeURIComponent(value)
            } else {
                otherParams.push(key+"="+value)
            }
        }
    });
    if (otherParams.length === 0) {
        return stamp;
    }
    return stamp + '?' + otherParams.join('&');
}

function checkChrome(runlink) {
    window.location = runlink;
    setTimeout(function () {
        if (window.isFocused) appNotFound();
    }, 500);
}

function checkIframe(runlink) {
    var ifr = document.createElement('iframe');
    ifr.style.cssText = 'width:0;height:0;position:absolute;';
    ifr.setAttribute('frameborder', '0');
    ifr.setAttribute('id', 'launch_scheme_iframe');
    ifr.setAttribute('src', runlink);
    document.body.appendChild(ifr);

    setTimeout(function () {
        ifr.parentNode.removeChild(ifr);
        if (window.isFocused) appNotFound();
    }, 1000);
}

function checkIos(runlink) {
    setTimeout(function() {
        window.location = runlink;
    }, 100);
}

function detectBrowser() {
    var ua = navigator.userAgent.toLowerCase();
    var checkUA = function (regexp) {
        return regexp.test(ua);
    };
    var isOpera = checkUA(/opera/) || checkUA(/opr\//),
        isWebKit = checkUA(/webkit/),
        isChrome = !isOpera && checkUA(/chrome/),
        isIE = (!isOpera && checkUA(/msie/)) || (ua.match(/rv:11\.0/i) && ua.match(/Trident\/7\.0/i)),
        isEdge = checkUA(/edge\/\d+/),
        isGecko = !isWebKit && checkUA(/gecko/),
        isFF = isGecko && !isIE,
        isMac = checkUA(/macintosh/),
        isIOS = checkUA(/iphone|ipad|ipod/),
        isSafari = isWebKit && (isMac || isIOS) && !isChrome && !isOpera;

    return {
        opera: isOpera,
        firefox: isFF,
        safari: isSafari,
        chrome: isChrome,
        msie: isIE,
        edge: isEdge,
        ios: isIOS
    };
}

function appNotFound() {
    //just stay on the page
}
window.isFocused = true;
window.addEventListener('focus', function () {
    window.isFocused = true;
});
window.addEventListener('blur', function () {
    window.isFocused = false;
});
