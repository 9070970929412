import React, { Component } from 'react';

import 'components/Shared/scss/inner.scss';
import { ReactComponent as VKTeamsLogo } from 'components/Shared/i/logo-VKTeams.svg';
import featuresVKTeams1webp from 'components/Shared/i/features-vk-teams-1.webp'
import featuresVKTeams1png from 'components/Shared/i/features-vk-teams-1.png'
import featuresVKTeams2webp from 'components/Shared/i/features-vk-teams-2.webp'
import featuresVKTeams2png from 'components/Shared/i/features-vk-teams-2.png'
import featuresVKTeams3webp from 'components/Shared/i/features-vk-teams-3.webp'
import featuresVKTeams3png from 'components/Shared/i/features-vk-teams-3.png'
import featuresVKTeams4webp from 'components/Shared/i/features-vk-teams-4.webp'
import featuresVKTeams4png from 'components/Shared/i/features-vk-teams-4.png'
import featuresVKTeams5webp from 'components/Shared/i/features-vk-teams-5.webp'
import featuresVKTeams5png from 'components/Shared/i/features-vk-teams-5.png'
import featuresVKTeams6webp from 'components/Shared/i/features-vk-teams-6.webp'
import featuresVKTeams6png from 'components/Shared/i/features-vk-teams-6.png'

import { copy } from 'components/copy'
import { goToDeeplink}  from 'components/deeplink'

class PageHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            versionsArray: []
        };
    }

    componentDidMount() {
        goToDeeplink(document.location.search);

        const adminURL = origin.replace (/(https?:\/\/)(.+?)\.(.+)/g, '$1admin.$3');
        const versionsURL = adminURL + "/api/misc/client_versions";
        fetch(versionsURL)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("Internal server error");
                }

                return response.json();
            })
            .then(data => {
                if (data.status && data.status.code === 200 && data.result) {
                    let versionsArray = [
                        ["myteam_release", "v " + data.result['myteam_release']]
                    ];

                    for (let key in data.result['myteam_clients']) {
                        versionsArray.push([key, "Myteam " + data.result['myteam_clients'][key]])
                    }

                    this.setState({
                        versionsArray: versionsArray
                    })
                }
            });
    }

    render() {
        let params = new URLSearchParams(this.props.location.search);
        let stamp = params.get('d')
        const { versionsArray } = this.state;
        const versions = Object.fromEntries(versionsArray);

        return (
            <div className="page">
                <header className="header" id="header">
                    <a href="/" className="header__logo" id="logo" data-goto-section="0">
                        <VKTeamsLogo alt="VK Teams"/>
                    </a>
                </header>
                <div className="download-page background_default" id="downloadLinks">
                    <h1>Установи VK Teams</h1>
                    <h3>{versions.myteam_release}</h3>
                    { stamp ?
                        <p className="download-page__note">Если уже установлен VK Teams, введи в поиске<br/>
                            <a href="#!" target="_blank" className="link__copy" onClick={copy}>{stamp}</a></p>
                        :
                        <br />
                    }
                    <div className="download-page__list">
                        <dl className="download-page__item -windows">
                            <dt className="download-page__title">Windows</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <a href="/downloads/win/x32/latest/vkteamssetup.exe">Windows 7 и новее 32-bit</a>
                                        <div>{versions.windows}</div>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="download-page__item -web">
                            <dt className="download-page__title">Web-версия</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <a href="/webim">Открыть Web-версию</a>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="download-page__item -android">
                            <dt className="download-page__title">Android</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <a href="/android">Загрузить приложение</a>
                                        <div>{versions.android}</div>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="download-page__item -linux">
                            <dt className="download-page__title">Linux</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <a href="/downloads/linux/x64/latest/vkteams.tar.xz">Linux 64-bit</a>
                                        <div>{versions.linux}</div>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="download-page__item -mac">
                            <dt className="download-page__title">macOS</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <a href="/downloads/mac/x64/latest/vkteams.dmg">Mac OS 10.12 и новее (DMG)</a>
                                        <div>{versions.macos}</div>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="download-page__item -ios">
                            <dt className="download-page__title">iOS</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <a href="/ios">Загрузить приложение</a>
                                        <div>{versions.ios}</div>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                    <div className="download-page-features">
                        <div className="download-page-features__block -invert -vbottom">
                            <div className="download-page-features__col">
                                <div className="download-page-features__img -features1">
                                    <picture>
                                        <source srcSet={featuresVKTeams1webp} type="image/webp" />
                                        <img src={featuresVKTeams1png} alt="" />
                                    </picture>
                                </div>
                            </div>
                            <div className="download-page-features__col">
                                <div className="download-page-features__text">
                                    <h3>Tолько сотрудники<br />компании</h3>
                                    <p>Когда выходит на работу новый коллега, он сразу появляется и в VK Teams. Если же
                                        кто-то решил
                                        поискать счастья на не связанном с компанией пути, он автоматически пропадёт из
                                        всех чатов</p>
                                </div>
                            </div>
                        </div>
                        <div className="download-page-features__block">
                            <div className="download-page-features__col">
                                <div className="download-page-features__img -features2">
                                    <picture>
                                        <source srcSet={featuresVKTeams2webp} type="image/webp" />
                                        <img src={featuresVKTeams2png} alt="" />
                                    </picture>
                                </div>
                            </div>
                            <div className="download-page-features__col">
                                <div className="download-page-features__text">
                                    <h3>Удобная<br />авторизация</h3>
                                    <p>Быстрая авторизация по одноразовому паролю из письма</p>
                                </div>
                            </div>
                        </div>
                        <div className="download-page-features__block -invert">
                            <div className="download-page-features__col">
                                <div className="download-page-features__img -features3">
                                    <picture>
                                        <source srcSet={featuresVKTeams3webp} type="image/webp" />
                                        <img src={featuresVKTeams3png} alt="" />
                                    </picture>
                                </div>
                            </div>
                            <div className="download-page-features__col">
                                <div className="download-page-features__text">
                                    <h3>Коллеги<br />в контактах</h3>
                                    <p>Ближайшие коллеги уже есть в контактах, а всех остальных можно найти по имени и
                                        фамилии</p>
                                </div>
                            </div>
                        </div>
                        <div className="download-page-features__block">
                            <div className="download-page-features__col">
                                <div className="download-page-features__img -features4">
                                    <picture>
                                        <source srcSet={featuresVKTeams4webp} type="image/webp" />
                                        <img src={featuresVKTeams4png} alt="" />
                                    </picture>
                                </div>
                            </div>
                            <div className="download-page-features__col">
                                <div className="download-page-features__text">
                                    <h3>Вся информация<br />в профиле</h3>
                                    <p>Информация о всех сотрудниках синхронизируется с интранетом</p>
                                </div>
                            </div>
                        </div>
                        <div className="download-page-features__block -invert">
                            <div className="download-page-features__col">
                                <div className="download-page-features__img -features5">
                                    <picture>
                                        <source srcSet={featuresVKTeams5webp} type="image/webp" />
                                        <img src={featuresVKTeams5png} alt="" />
                                    </picture>
                                </div>
                            </div>
                            <div className="download-page-features__col">
                                <div className="download-page-features__text">
                                    <h3>Мгновенные звонки<br />по ссылкам</h3>
                                    <p>Групповые аудио и видеозвонки, а также возможность создать звонки по ссылке</p>
                                </div>
                            </div>
                        </div>
                        <div className="download-page-features__block">
                            <div className="download-page-features__col">
                                <div className="download-page-features__img -features6">
                                    <picture>
                                        <source srcSet={featuresVKTeams6webp} type="image/webp" />
                                        <img src={featuresVKTeams6png} alt="" />
                                    </picture>
                                </div>
                            </div>
                            <div className="download-page-features__col">
                                <div className="download-page-features__text">
                                    <h3>Интеграция через<br />Bot API</h3>
                                    <p>Открытое <a href="https://myteam.mail.ru/botapi/">Bot API</a>. Пишите своих
                                        ботов и&nbsp;пользуйтесь готовыми ботами коллег
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageHome;
